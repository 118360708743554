import React, { useEffect, useState } from 'react';
import { Flex, Image } from 'rebass/styled-components';

// STYLES
import {
    gallery_item_display_container_props,
    gallery_item_display_image_props,
    gallery_item_display_hamburger_props,
    gallery_item_display_hamburger_container_props,
    gallery_item_display_controls_props
} from './styles';

// COMPONENTS
import Hamburger from './../../atoms/hamburger'

import { calculation_dimension }from './../../molecules/navigation/styles';

export default function GalleryItemDisplay(props) {

  const { data, innerRefs, closeImage, activePicture } = props;

  // STATE
  const [currentPic, setCurrentPic] = useState(false);

  useEffect(() => {
    setCurrentPic(activePicture)
  }, [activePicture])

  const handle_controls_buttons = (data, status) => {
      const allPictures = data.allFile.edges.filter(file => file.node.name.includes('_b'))
      .map(file => file.node.name)
      .sort((a, b) => 
      Number(a.slice(8, ).replace('_b', '')) - Number(b.slice(8, ).replace('_b', '')));

      const currentPictureIndex = allPictures.indexOf(currentPic.src.replace('.jpg', ''))
      const lessThan = currentPictureIndex < allPictures.length - 1;
      const greaterThan = currentPictureIndex > 0;

      if (status === 'prev') {
        setCurrentPic({ src: `${allPictures[greaterThan ? currentPictureIndex - 1 : 0]}.jpg` })
      } else {
        setCurrentPic({ src: `${allPictures[lessThan ? currentPictureIndex + 1 : allPictures.length - 1]}.jpg` })
      }
  }

  return (
    <Flex ref={innerRefs.container} {...gallery_item_display_container_props}>
        <Flex ref={innerRefs.icon} {...gallery_item_display_hamburger_container_props}>
            <Hamburger {...gallery_item_display_hamburger_props(calculation_dimension)} onClick={closeImage} />
        </Flex>
        {
            currentPic && <Image ref={innerRefs.image} 
            src={`${data.allFile.edges.filter(file => 
                file.node.name === currentPic.src.replace('.jpg', '')
            )[0].node.publicURL}`} alt={currentPic.src}
            {...gallery_item_display_image_props} />
        }
        <Flex {...gallery_item_display_controls_props('left')}
        onClick={() => handle_controls_buttons(data, 'prev')}>{`${'<'}`}</Flex>
        <Flex {...gallery_item_display_controls_props('right')}
        onClick={() => handle_controls_buttons(data, 'next')}>{`${'>'}`}</Flex>
    </Flex>
  );
}
