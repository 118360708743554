import React from 'react'

// COMPONENTS
import SEO from './../components/templates/seo';
import ProjectsDisplaySection from './../components/organisms/projectsDisplay';

export default function Projects(props) {
    return (
        <>
            <SEO title='Projekty' />
            <ProjectsDisplaySection />
        </>
    )
}
