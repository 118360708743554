import theme from './../../../gatsby-plugin-theme-ui';

// PROPS

export const image_styles = (pic, data) => ({
    content: '""',
    position: 'absolute',
    top: 0,
    width: '100%',
    height: '100%',
    background: `url(${data.allFile.edges.filter(file => file.node.name === pic.src.replace('.jpg', ''))[0].node.publicURL}) no-repeat 100% 100% / cover`,
})

export const gallery_item_container_props = (pic1, pic2, data, index, container_ref) => ({
    sx: {
        flexFlow: 'column nowrap',
        position: 'relative',
        width: ['calc(100% - 2.5rem)', 'calc(50% - 1.25rem)', 'calc(33.3% - 1.25rem)'],
        height: 250,
        opacity: 0,
        visibility: 'hidden',
        background: theme.colors.lightgrey,
        transition: `opacity 600ms ease-in-out ${125*index}ms`,
        margin: ['0.625rem 1.25rem', '0.625rem', '0.625rem'],
        ':after': {
            ...image_styles(pic1, data),
            zIndex: 2,
            opacity: 1,
            transition: 'opacity 650ms ease-in-out'
        },
        ':hover:after': {
            opacity: 0,
            transition: 'opacity 650ms ease-in-out'
        },
        ':before': {
            ...image_styles(pic2, data),
            zIndex: 1,
            opacity: 1,
            transition: 'opacity 650ms ease-in-out 200ms'
        },
        ':hover > div': {
            opacity: 1,
            transition: 'opacity 400ms ease-in-out 200ms'
        }
    },
    ref: container_ref
})

export const description_container_props = {
sx: {
    position: 'absolute',
    width: '100%',
    bottom: 0,
    padding: '0.875rem',
    zIndex: 3,
    background: `linear-gradient(0deg, rgba(${theme.colors.lightgrey_rgba}, 1) -60%, rgba(${theme.colors.lightgrey_rgba}, 0))`,
    opacity: 0,
    transition: 'opacity 400ms ease-in-out'
}
}

export const description_heading_props = {
    as: 'h2',
    fontSize: [22, 24],
    fontWeight: 700,
    textAlign: 'left',
    margin: '0 0 0.25rem 0'
}

export const description_border_props = {
    relative: true,
    width: '75%',
    height: 5
}

export const description_text_props = {
    as: 'p',
    fontSize: [14, 16],
    fontWeight: 700,
    margin: '0.5rem 0 0 0',
    lineHeight: '1.5rem'
}