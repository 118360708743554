import theme from './../../../gatsby-plugin-theme-ui';

// PROPS

export const gallery_item_display_container_props = {
    sx: {
        position: 'fixed',
        top: 0,
        right: 0,
        left: 0,
        bottom: 0,
        width: '100%',
        height: '100%',
        background: theme.colors.black,
        zIndex: 1000,
        visibility: 'hidden',
        opacity: 0,
        justifyContent: 'center',
        alignItems: ['flex-start', 'center', 'center'],
        padding: ['104px 0', '104px 0', '104px 0'],
        overflowY: 'auto'
    }
}

export const gallery_item_display_image_props = {
    width: 'auto',
    maxHeight: 788,
    minHeight: 320,
    height: '100%'
}

export const gallery_item_display_hamburger_container_props = {
    sx: {
        width: '100%',
        height: [104, 104, 144],
        position: 'absolute',
        top: 0,
        right: 0,
        padding: '0 1rem',
        alignItems: 'center',
        opacity: 0
    }
}

export const gallery_item_display_hamburger_props = calculate => ({
    content: 'wróć',
    close_btn: true,
    width: calculate(78),
    height: calculate(48),
    color: '#FFF'
})

export const gallery_item_display_controls_props = side => ({
    as: 'button',
    sx: {
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        left: side === 'left' ? 0 : null,
        right: side === 'left' ? null : 0,
        top: '50%',
        transform: 'translateY(-50%)',
        width: [50, 75, 100],
        height: 50,
        background: `linear-gradient(${
            side === 'left' ? '90deg' : '-90deg'}, rgba(151,2,27, 0) -20%, rgba(151,2,27, 1))`,
        cursor: 'pointer',
        border: 'none',
        fontSize: 20,
        color: '#FFF',
        fontWeight: 700,
        fontFamily: theme.fonts.heading,
        zIndex: 1002,
        ':focus': {
            outline: 'none'
        }
    }
})