import React, { useEffect, useRef } from 'react';
import { Flex, Box, Image } from 'rebass/styled-components';
import { TweenMax } from 'gsap';
import theme from './../../../gatsby-plugin-theme-ui';

// MEDIA
import Expand from './../../../media/svg/expand.svg';

// STYLES
import {
  gallery_item_container_props,
  description_container_props,
  description_heading_props,
  description_border_props,
  description_text_props
} from './styles'

// COMPONENTS
import Heading from './../../atoms/heading';
import Text from './../../atoms/text';
import Border from './../../atoms/border';

export default React.memo(function GalleryItem(props) {

  const { pic1, pic2, title, subtitle, index, isMounted, openImage, data } = props;

  let container_ref = useRef();
  let tween = useRef();

  useEffect(() => {
    tween.current = TweenMax;

    const container_animation = tween.current.to(
      container_ref.current, 0.2,
      { opacity: isMounted ? 1 : 0, autoAlpha: isMounted ? 1 : 0}
    )

    container_animation.play();

  }, [isMounted])

  return (
    <Flex {...gallery_item_container_props(pic1, pic2, data, 
    index, container_ref)}>
      <Box {...description_container_props}>
          <Heading {...description_heading_props}>{title}</Heading>
          <Border {...description_border_props} />
          <Text {...description_text_props}>{ subtitle }</Text>
      </Box>
      <Flex sx={{
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        width: 50,
        height: '3.5rem',
        top: 0,
        right: 0,
        background: `linear-gradient(-90deg, rgba(${theme.colors.lightgrey_rgba}, 1) 10%, rgba(${theme.colors.lightgrey_rgba}, 0))`,
        zIndex: 100,
        cursor: 'pointer',
        opacity: 0,
        transition: 'opacity 400ms ease-in-out'
      }} onClick={openImage}>
        <Image sx={{ height: 18, width: 'auto' }} src={Expand} alt='expand.svg' />
        <Border position={{ top: '3.5rem', right: 0 }}
        width={50} height={5} zIndex={100}
        transform={{ transform: 'rotate(180deg)' }} />
      </Flex>
    </Flex>
  );
}, function(prevProps, nextProps) {
  return prevProps.isMounted === nextProps.isMounted;
})
