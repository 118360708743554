export const gallery_list = [
    {
        title: 'Kominek narożny',
        subtitle: 'Wkład z boczną szybą w zabudowie z cegły',
        pic1: {
            src: 'projekt_1_a.jpg',
            alt: 'projekt_1_a'
        },
        pic2: {
            src: 'projekt_1_b.jpg',
            alt: 'projekt_1_b'
        }
    },
    {
        title: 'Kominek kaflowy',
        subtitle: 'Wkład pionowy w klasycznej zabudowie kaflowej z niszą na drewno',
        pic1: {
            src: 'projekt_2_a.jpg',
            alt: 'projekt_2_a'
        },
        pic2: {
            src: 'projekt_2_b.jpg',
            alt: 'projekt_2_b'
        }
    },
    {
        title: 'Kominek narożny',
        subtitle: 'Wkład z boczną szybą w prostej nowoczesnej zabudowie',
        pic1: {
            src: 'projekt_3_a.jpg',
            alt: 'projekt_3_a'
        },
        pic2: {
            src: 'projekt_3_b.jpg',
            alt: 'projekt_3_b'
        }
    },
    {
        title: 'Kominek portalowy',
        subtitle: 'Klasyczny wkład osadzony w portalu kaflowym oraz sztuczną ścianką umożliwiającą stworzenie niszy na drewno',
        pic1: {
            src: 'projekt_4_a.jpg',
            alt: 'projekt_4_a'
        },
        pic2: {
            src: 'projekt_4_b.jpg',
            alt: 'projekt_4_b'
        }
    },
    {
        title: 'Kominek elektryczny',
        subtitle: 'Wkład elektryczny z efektem 3d w nowoczesnej szklanej zabudowie',
        pic1: {
            src: 'projekt_5_a.jpg',
            alt: 'projekt_5_a'
        },
        pic2: {
            src: 'projekt_5_b.jpg',
            alt: 'projekt_5_b'
        }
    },
    {
        title: 'Kominek panoramiczny',
        subtitle: 'Wkład poziomy, panoramiczny w nowoczesnej zabudowie z kamienia',
        pic1: {
            src: 'projekt_6_a.jpg',
            alt: 'projekt_6_a'
        },
        pic2: {
            src: 'projekt_6_b.jpg',
            alt: 'projekt_6_b'
        }
    },
    {
        title: 'Kominek elektryczny kaflowy',
        subtitle: 'Wkład elektryczny z efektem 3d w pałacowej zabudowie kaflowej',
        pic1: {
            src: 'projekt_7_a.jpg',
            alt: 'projekt_7_a'
        },
        pic2: {
            src: 'projekt_7_b.jpg',
            alt: 'projekt_7_b'
        }
    },
    {
        title: 'Kominek kaflowy',
        subtitle: 'Wkład pionowy w prostej zabudowie kaflowej',
        pic1: {
            src: 'projekt_8_a.jpg',
            alt: 'projekt_8_a'
        },
        pic2: {
            src: 'projekt_8_b.jpg',
            alt: 'projekt_8_b'
        }
    },
    {
        title: 'Kominek narożny',
        subtitle: 'Wkład z boczną szybą w zabudowie z cegły z niszą na drewno',
        pic1: {
            src: 'projekt_9_a.jpg',
            alt: 'projekt_9_a'
        },
        pic2: {
            src: 'projekt_9_b.jpg',
            alt: 'projekt_9_b'
        }
    },
    {
        title: 'Kominek kaflowy',
        subtitle: 'Wkład w nowoczesnej zabudowie kaflowej z niszą na drewno',
        pic1: {
            src: 'projekt_10_a.jpg',
            alt: 'projekt_10_a'
        },
        pic2: {
            src: 'projekt_10_b.jpg',
            alt: 'projekt_10_b'
        }
    },
    {
        title: 'Kominek trójstronny',
        subtitle: 'Wkład kominkowy z trzema szybami w prostej zabudowie',
        pic1: {
            src: 'projekt_11_a.jpg',
            alt: 'projekt_11_a'
        },
        pic2: {
            src: 'projekt_11_b.jpg',
            alt: 'projekt_11_b'
        }
    },
    {
        title: 'Kominek pionowy',
        subtitle: 'Wkład pionowy w prostej zabudowie z niszą',
        pic1: {
            src: 'projekt_12_a.jpg',
            alt: 'projekt_12_a'
        },
        pic2: {
            src: 'projekt_12_b.jpg',
            alt: 'projekt_12_b'
        }
    },
    {
        title: 'Kominek z półką',
        subtitle: 'Wkład z gilotyną w zabudowie z półką oraz ażurową niszą na drewno',
        pic1: {
            src: 'projekt_13_a.jpg',
            alt: 'projekt_13_a'
        },
        pic2: {
            src: 'projekt_13_b.jpg',
            alt: 'projekt_13_b'
        }
    },
    {
        title: 'Kominek pionowy',
        subtitle: 'Wkład pionowy w nowoczesnej szklanej zabudowie',
        pic1: {
            src: 'projekt_14_a.jpg',
            alt: 'projekt_14_a'
        },
        pic2: {
            src: 'projekt_14_b.jpg',
            alt: 'projekt_14_b'
        }
    },
    {
        title: 'Kominek kaflowy',
        subtitle: 'Wkład w klasycznej kaflowej zabudowie z kaflową ławką',
        pic1: {
            src: 'projekt_15_a.jpg',
            alt: 'projekt_15_a'
        },
        pic2: {
            src: 'projekt_15_b.jpg',
            alt: 'projekt_15_b'
        }
    }
]