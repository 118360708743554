export const projects_display_section_props = {
    sx: {
        width: '100%',
        height: 'auto',
        flexFlow: 'column nowrap',
        margin: '0 0 5rem 0'
    }
}

export const projects_display_heading_props = {
    as: 'h1',
    background_text: 'Projekty',
    margin: '2rem auto 3.5rem auto',
    padding: '0 1.25rem'
}

export const projects_display_container_props = {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    padding: [0, '0 0.625rem', 0]
}