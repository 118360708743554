import React, { useState, useEffect, useRef } from 'react';
import { Flex } from 'rebass/styled-components';
import shortid from 'shortid';
import { TimelineMax, TweenMax } from 'gsap';
import { StaticQuery, graphql } from 'gatsby';

// DATA
import { gallery_list } from './galleryList';

// STYLES
import {
  projects_display_section_props,
  projects_display_heading_props,
  projects_display_container_props
} from './styles';

// COMPONENTS
import Section from './../../atoms/section';
import Heading from './../../atoms/heading';
import GalleryItem from './../../molecules/galleryItem';
import GalleryItemDisplay from './../../molecules/galleryItemDisplay';
import Loader from './../../atoms/loader';

export default function ProjectsDisplay() {

  // REF
  let full_image_ref = useRef();
  let full_image_close_btn_ref = useRef();
  let full_image_display_ref = useRef();
  let tl = useRef();
  let tween = useRef();

  // STATE
  const [isMounted, setIsMounted] = useState(false);
  const [activePicture, setActivePicture] = useState(false);

  useEffect(() => {
    setTimeout(() => setIsMounted(true), 600);
  }, [setIsMounted])

  const handle_full_image = (status, picture) => {
    if (picture) setActivePicture(picture);
    tl.current = new TimelineMax({ paused: true });
    tween.current = TweenMax;

    const full_image_animation = tween.current.to(
      full_image_ref.current, 0.6,
      { opacity: status === 'open' ? 1 : 0, autoAlpha: status === 'open' ? 1 : 0 }
    )

    const full_image_close_btn_animation = tween.current.to(
      full_image_close_btn_ref.current, 0.2, { opacity: status === 'open' ? 1 : 0 }
    )

    const full_image_display_animation = tween.current.to(
      full_image_display_ref.current, 0.4, { opacity: status === 'open' ? 1 : 0 }
    )

    tl.current.add(full_image_animation);
    tl.current.add(full_image_close_btn_animation);
    tl.current.add(full_image_display_animation);

    tl.current.play();
  }

  return (
    <StaticQuery
    query={
      graphql`
      {
        allFile(filter: { extension: { eq: "jpg" } }) {
          edges {
            node {
              publicURL
              name
            }
          }
        }
      }`
    }
    render={data => {

      const gallery_items = gallery_list.map((item, i) =>
        <GalleryItem
        key={shortid.generate()}
        index={i}
        title={item.title}
        subtitle={item.subtitle}
        pic1={item.pic1}
        pic2={item.pic2}
        isMounted={isMounted}
        openImage={() => handle_full_image('open', item.pic2)}
        data={data} />
      )

      return (
        <Section {...projects_display_section_props}>
          <Heading {...projects_display_heading_props}>Nasze projekty</Heading>
          <Flex {...projects_display_container_props}>
              { gallery_items }
          </Flex>
          <GalleryItemDisplay 
          innerRefs={{
            container: full_image_ref,
            icon: full_image_close_btn_ref,
            image: full_image_display_ref
          }}
          closeImage={() => handle_full_image('close')} activePicture={activePicture} data={data} />
          <Loader isMounted={isMounted} />
        </Section>
      )
    }}
    />
  );
}