import React, { useEffect, useRef } from 'react';
import { Image } from 'rebass/styled-components';
import { TweenMax } from 'gsap';

// MEDIA
import Icon from './../../../media/svg/loader.svg';

export default function Loader(props) {

    const { isMounted, position } = props;

    // REF
    let loader_ref = useRef();
    let tween = useRef();

    useEffect(() => {
        tween.current = TweenMax;

        if(isMounted) {
          const loader_animation = tween.current.to(
            loader_ref.current, 0.2, { opacity: 0, autoAlpha: 0 }
          )

          loader_animation.play();
        }

    }, [isMounted])

  return (
    <Image sx={{
        width: 60,
        height: 'auto',
        position: position ? position : 'fixed',
        top: '50%',
        transform: 'translateY(-50%)',
        right: 0, left: 0, margin: '0 auto', zIndex: -1,
        opacity: 1,
        visibility: 'visibility'
      }} src={Icon} alt='loader.svg' ref={loader_ref} />
  );
}
